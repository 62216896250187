import { defaultTimeRange, formatDateToText } from "utils/seismicityUtils";
import { UseDialog } from "hooks/UseDialog"
import { useSeismicityRequest } from "hooks/useSeismicityRequest";
import { useState } from "react";
import { useEffect } from "react"

function SeismicityDialog({ show, setShow, onClose, title }) {

   const [ eventsCount, setEventsCount ] = useState( 0 );
   const { getEventsCount } = useSeismicityRequest();

   useEffect(() => {
      async function loadEventsCount(){
         const count = await getEventsCount( defaultTimeRange.startDate, defaultTimeRange.endDate );
         if ( count ){
            setEventsCount( count );
            setShow( true );
         }
      }
      loadEventsCount();
   // eslint-disable-next-line
   }, []);

   return (
      show &&
         <UseDialog 
            open = { true } titleCenter
            title = { title }
            message = { <>
               El Servicio Geológico Colombiano informa que en la semana del { formatDateToText( defaultTimeRange ) }
               &nbsp;se reportaron { eventsCount } sismos en Colombia, los eventos destacados se listan en la tabla de sismos destacados 
               y se enumeran en el mapa. Es posible modificar el periodo de animación de los sismos según el rango de
               fechas que se elija en el panel de configuración.
               </>
            }
            btn = {{
               action: onClose,
               text: "ACEPTAR"
            }}
         />
   )
}
export default SeismicityDialog