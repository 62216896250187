import { Box } from "@mui/material"
import { CatalogDateFilter } from "Components/Atoms/A_EQViewer/CatalogDateFilter"
import { defaultTimeRange } from "utils/seismicityUtils";
import { endOfDay, formatISO } from "date-fns";

import { useContext } from "react";
import { SeismicityContext } from "context/SeismicityContext/SeismicityContext";
import { useState } from "react";

const timeLimits = {
   minDate: new Date(1644, 0, 1),
   maxDate: endOfDay( new Date() ),
}

function SettingsFilterDate({ title }) {

   const { requestTimeRange, setRequestTimeRange, setStopSimulation, setSelectedEvent } = useContext( SeismicityContext );

   const [ { startDate, endDate }, setDateRange ] = useState({ ...defaultTimeRange });
   // const [ isOutMaxRange, setOutMaxRange ] = useState( false );
   const isOutMaxRange = false;
   const [ isErrors, setError ] = useState( false );
   
   const { maxDate, minDate } = timeLimits;

   const handleDate = ( value ) => {

      // const numberOfDays = differenceInDays( new Date( value[1] ), new Date( value[0] ) );
      // if ( !isNaN( numberOfDays ) && numberOfDays > 15 ){
      //    setOutMaxRange( true );
      // } else {
      //    setOutMaxRange( false );
      // }

      setDateRange({
         startDate : value[0],
         endDate   : value[1],
      })
   }

   const handleDateError = (err) => setError( err );

   const handleApplyButton = () => {

      const start = formatISO( new Date( startDate ) ).slice(0, -6); // formats to ISO and remove hour zone
      const end = formatISO( new Date( endDate ) ).slice(0, -6);

      if ( !(start === requestTimeRange.startDate && end === requestTimeRange.endDate) ){
         setRequestTimeRange({
            startDate: start,
            endDate: end,
         });
         setStopSimulation( false );
         setSelectedEvent( null );
      }
   }

   const handleClickDateFilter = () => {
      setStopSimulation( true );
   }

   return (
      <div className="SettingsPanelItem" style={{ maxWidth: "250px" }}>
         <div className="SettingsItemTitle">
            { title }
         </div>
         <div className="SettingsItemContent" style={{  marginBottom: "12px" }}>
            <Box ml={3} mt={1}>
               <CatalogDateFilter
                  onChange = { handleDate }
                  onError = { handleDateError }
                  minDate = { minDate }
                  maxDate = { maxDate }
                  startDate = { startDate }
                  endDate = { endDate }
                  onClick={ handleClickDateFilter }
               />
            </Box>
            {
               isOutMaxRange &&
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled Mui-required" 
                     style={{ textAlign: "center" }}
                  >
                     El rango no debe ser mayor a 15 días
                  </p>
            } 
         </div>

         <Box display="flex" justifyContent="center">
            <button 
               className = "filterButton"
               onClick = { handleApplyButton }
               disabled = { isErrors || isOutMaxRange }
            >
               Aplicar
            </button>
         </Box>
         
      </div>
   )
}
export default SettingsFilterDate